<template>
  
  <!-- Datos del pagues -->
  <teleport to="#breadcrumb">
    <ul class="navbar-nav flex-row">
      <li>
        <div class="page-header">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"> <a> Home </a></li>
            </ol>
          </nav>
        </div>
      </li>
    </ul>
    <cambiar/>
  </teleport>


  <div class="layout-px-spacing cards-inicio">
    
  <div v-if="user.codEmpresa== '113'">
    <a :href="urlAppAnterior" target="_blank" class="btn btn-primary mr-2 mt-4 ml-2">
      Ir a App Kupi
    </a>
  </div>
    <div v-scroll-spy class="row">
        <div v-for="(card, index) in cards" :key="index" class="col-lg-4 col-md-4 col-sm-6  layout-top-spacing">
            <div class="m-2 statbox box box-shadow">
                <div class="panel-body">
                  <router-link v-if="index!==5" :to="card.url" class="dropdown-toggle" @click="toggleMobileMenu">
                    <div class="card component-card">
                        <div class="card-body">
                            <div class="icon-svg">
                              <center>
                                <img :src="card.graphic" alt="graphic">
                              </center>
                            </div>
                            <h5 class="card-title">{{card.title}}</h5>
                        </div>
                    </div>
                  </router-link>
                  <a v-else :href="card.url">
                    <div class="card component-card">
                        <div class="card-body">
                            <div class="icon-svg">
                              <center>
                                <img :src="card.graphic" alt="graphic">
                              </center>
                            </div>
                            <h5 class="card-title">{{card.title}}</h5>
                        </div>
                    </div>
                  </a>
                </div>
            </div>
        </div>
    </div>
  </div>
  <codPerfilJWT></codPerfilJWT>
  <logOut></logOut>

</template>

<script setup>

  import '@/assets/sass/scrollspyNav.scss';
  import '@/assets/sass/components/cards/card.scss';
  import '@/assets/sass/pages/inicio.scss';
  
</script>


<script>
  import logOut from '@/views/logOut/logOut.vue';
  import cambiar from '@/views/kupiComercios/pagos/components/cambiarEmpresa.vue';
  import { useStore } from 'vuex';
  import codPerfilJWT from '@/views/kupiComercios/codPerfil/codPerfilJWT.vue';


  export default {
    data() {

      let store = useStore();
    
      const user = store.getters.user;
      let comercios = store.getters.comercios;

      console.log(comercios);
    
      const kb = []
      const  tokenLogin = store.getters.user.tokenLogin
      const urlAppAnterior = `https://app.kupi.com.co/app/login.php?tokenLogin=${tokenLogin}`
    // console.log('comercios en data', comercios.length);
    
      for (let i = 0; i < comercios.length; i++) {
        const idd = i
        
        if (comercios[i].codEmpresa != user.codEmpresa){    
          const item = {
            id          : idd,
            title       : comercios[i].nomEmpresa,
            graphic     : comercios[i].urlImagen,
            sucursales  : comercios[i].sucursales,
            // graphic     : require('@/assets/images/pages/kupi.png'),
            codEmpresa  : comercios[i].codEmpresa,
            url         : '/pagos/PagoQR'
          }
          kb.push(item)
        }
      }

        const cards = [
          {id: 1, title: 'PAGO CON QR',             description: '', graphic: require('@/assets/images/pages/G1.svg'), url: '/pagos/pagoQR'},
          {id: 2, title: 'PAGO CON PIN',            description: '', graphic: require('@/assets/images/pages/G2.svg'), url: '/pagos/pagoPin'},
          {id: 3, title: 'CONSULTAR SALDO',          description: '', graphic: require('@/assets/images/pages/G3.svg'), url: '/pagos/ConsultarSaldo'},
          {id: 4, title: 'MIS VENTAS',              description: '', graphic: require('@/assets/images/pages/G4.svg'), url: '/pagos/misVentas'},
          {id: 5, title: 'MI CUENTA',               description: '', graphic: require('@/assets/images/pages/G5.svg'), url: '/miCuenta'},
          {id: 6, title: 'SOPORTE',                 description: '', graphic: require('@/assets/images/pages/G6.svg'), url: 'https://api.whatsapp.com/send?phone=573167448990&text=&source=&data='},
        ]
        return {
          cards,
          user,
          urlAppAnterior,
          kb
        }
    },
    methods: {
      getImgUrl(index) {
        return '@/assets/images/G' + (index+1) + '.svg'
      },
      CambioComercio(item){

      // console.log('Selecciono la empresa, ', item.title, ' con Codempresa: ', item.codEmpresa, ' y tiene este numero de sucursales:', item.sucursales)
      const data = {
        codEmpresa: item.codEmpresa,
        nomEmpresa: item.title,
        sucursales: item.sucursales,
        urlImagen:  item.graphic
      }
      
      store.dispatch('addComercioActual', data)
      
      if (store.getters.user.codSucursal == -1) {

        var sucursalesComercioResponse = axiosWS3.sucursalesComercioResponse(data.codEmpresa);
        sucursalesComercioResponse.then( response => {
          //si la respuesta es positiva
          if (response.data.status === true) {
            store.dispatch('addSucursales', response.data.comercios)
            store.state.user.codSucursal = -1
            store.state.user.nomSucursal = 'Seleccione Sucursal'
            router.replace('/sucursales')
          }
          else {
           store.state.user.codSucursal = 0
           store.state.user.nomSucursal = 'Sin Sucursal, comuníquese con proveedor'
          }
        })
      }
      else {
        // console.log(`Presiono la carta con ID: ${id}, su nomEmpressa: ${data.nomEmpresa}, su codEmpresa ${data.codEmpresa} y sucursales en ${data.sucursales}`)
        router.push('/Inicio')
      }
    },

    cambiarSucursal(){
      store.dispatch('reload_page', true);
      // console.log('State changing, ',store.getters.reloadPage)
      router.replace("/sucursales")
    }
    },
    components: {
      logOut,
      cambiar,
      codPerfilJWT
    }
    
  }
</script>

<style>
  #header-template {
    margin-left: 200px;
  }
  #first-letter {
    text-transform:capitalize;
  }
</style>