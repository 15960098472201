import { createStore } from 'vuex';

import actions from './controllers/actions';
import state from './controllers/state';
import mutations from './controllers/mutations';
import getters from './controllers/getters';
import createPersistedState from 'vuex-persistedstate'

export default new createStore({
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
    modules: {},
    plugins: [createPersistedState()]
});
