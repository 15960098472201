import i18n from '@/i18n';

///////////////////////////////////////
/// Las mutaciones son los setters de la aplicacion
/// son aquellas acciones que realizan los cambios de estados 
//////////////////////////////////////

const mutations = {

  setLayout(state, payload) {
      state.layout = payload;
  },
  toggleSideBar(state, value) {
      state.is_show_sidebar = value;
  },
  toggleSearch(state, value) {
      state.is_show_search = value;
  },
  toggleLocale(state, value) {
      value = value || 'en';
      i18n.global.locale = value;
      localStorage.setItem('i18n_locale', value);
      state.locale = value;
  },

  toggleDarkMode(state, value) {
      //light|dark|system
      value = value || 'light';
      localStorage.setItem('dark_mode', value);
      state.dark_mode = value;
      if (value == 'light') {
          state.is_dark_mode = false;
      } else if (value == 'dark') {
          state.is_dark_mode = true;
      } else if (value == 'system') {
          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
              state.is_dark_mode = true;
          } else {
              state.is_dark_mode = false;
          }
      }

      if (state.is_dark_mode) {
          document.querySelector('body').classList.add('dark');
      } else {
          document.querySelector('body').classList.remove('dark');
      }
  },

  toggleMenuStyle(state, value) {
      //horizontal|vertical|collapsible-vertical
      value = value || '';
      localStorage.setItem('menu_style', value);
      state.menu_style = value;
      if (!value || value === 'vertical') {
          state.is_show_sidebar = true;
      } else if (value === 'collapsible-vertical') {
          state.is_show_sidebar = false;
      }
  },

  toggleLayoutStyle(state, value) {
      //boxed-layout|large-boxed-layout|full
      value = value || '';
      localStorage.setItem('layout_style', value);
      state.layout_style = value;
  },


  /////////////////////////////////////////
  ///////////  Mutaciones de Kupi
  ////////////////////////////////////////

  is_logged (state, value) {
      value = value || '';
      localStorage.setItem('is_logged', value);
      state.is_logged = value;
  },


  reload_page (state, value) {

    localStorage.setItem('reloadPage', value);
    state.reloadPage = value;
  },


  user_logIn (state, data) {
    data = data || '';
    localStorage.setItem(state.user, data);
    state.user = data;
  },


  user_logOut (state) {

    const user = {
      codUsuario: "",
      nomUsuario: "",
      apeUsuario: "",
      numDocumento: "",
      tokenLogin: "",
      MyToken: "",
      //DATOS PARA KUPI COMERCIOS
      codEmpresa: "",
      nomEmpresa: "",
      codSucursal: "",
      sucursales: "",
      cantComercios: "",
      urlImagen: ""
    }

    // console.log('Usuario borrado exitosamente');

    localStorage.setItem(state.user, user);
    state.user = user;

  },

  
  // Sucursales del comercio actual seleccionado por el usuario
  UserSucursalesComercioActual (state, sucursales) {
    state.sucursales = []
    console.log('cantidad de sucursales, ', sucursales.length);

    for (let i = 0; i < sucursales.length; i++) {
      const sucursal = {
        codSucursal   : sucursales[i].codSucursal,
        nomSucursal   : sucursales[i].nomSucursal,
        codEmpresa    : sucursales[i].codEmpresa,
        codComercio   : sucursales[i].codComercio,
        codFranquicia : sucursales[i].codFranquicia,
        codEstado     : sucursales[i].codEstado,
        nomEmpresa    : sucursales[i].nomEmpresa,
        type          : sucursales[i].type
      }
      state.sucursales.push(sucursal)
    }
  },


  //SET sucursal actual del usuario
  UserSetSucursal (state, sucursal) {
    state.user.codSucursal = sucursal.codSucursal
    state.user.nomSucursal = sucursal.nomSucursal
  },


  //SET nuevo comercio actual
  UserComercioActual (state, data) {

    state.user.codEmpresa = data.codEmpresa
    state.user.nomEmpresa = data.nomEmpresa
    state.user.sucursales = data.sucursales
    state.user.urlImagen = data.urlImagen

    // console.log(`Comprobando numero de sucursales: ${data.sucursales}`)

    if (data.sucursales == 1) {
      state.sucursales = []
      state.user.codSucursal = -1
      state.user.nomSucursal = 'Seleccione Sucursal'
      // console.log('El comercio tiene sucursales')
    } else {
      // console.log('El comercio NO tiene sucursales')
      state.user.codSucursal = '0'
      state.user.nomSucursal = 'Sin Sucursal'
    }
  },


  // Guarda los comercios del usuario
  UserComercios (state, comercios) {
    //Limpio los comercios anteriormente asignados en la variable de estado
    state.comercios = []

    for (let i = 0; i < comercios.length; i++) {
      const comercio = {
        codEmpresa : comercios[i].codEmpresa,
        nomEmpresa : comercios[i].nomEmpresa,
        urlImagen: comercios[i].urlImagen,
        type : comercios[i].type,
        sucursales: comercios[i].sucursales
      }
      state.comercios.push(comercio)
    }
    // state.comercios = [comercio, ... state.comercios]
  },


  UserDeleteSucursales(state, data) {

    data = data || '';
    localStorage.setItem(state.sucursales, data);
    state.sucursales = data;
  },


  UserDeleteComercios(state, data) {
    data = data || '';
    localStorage.setItem(state.comercios, data);
    state.comercios = data;
  },


  // /////////////////////////////////////////////
  // COMPONENTES PAGO
  // /////////////////////////////////////////////
  KcAddPagoPin(state, data) {

    data = data || '';
    localStorage.setItem(state.pagoPin, data);
    state.pagoPin = data;

  },


  KcAddPagoQR (state, data) {

    data = data || '';
    localStorage.setItem(state.pagoQR, data);
    state.pagoQR = data;
    
  },


  KcDelPagoPin (state, data) {

    data = data || '';
    localStorage.setItem(state.pagoPin, data);
    state.pagoPin = data;
    
  },


  KcDelPagoQR (state, data) {

    data = data || '';
    localStorage.setItem(state.pagoQR, data);
    state.pagoQR = data;
    
  },


  // /////////////////////////////////////////////
  // FINAL COMPONENTES PAGO
  // /////////////////////////////////////////////
}

export default mutations

