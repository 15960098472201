<template>

    <div class="navbar-item flex-row ms-md-auto ">
      <div class=" d-flex align-items-center">
        <a class="d-flex align-items-center">
          <li class="btn btn-success ms-2"> <a href="/comercios"> Cambiar </a> </li>
          <div class="dropdown nav-item notification-dropdown btn-group">
            <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
              <img :src="user.urlImagen" alt="graphic" width="30" height="30" id="comercios-images">
              <span class="ms-2"> {{user.nomEmpresa}} </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify">
              <li role="presentation">
                  <a href="javascript:;" class="dropdown-item">
                      <div class="media server-log">
                          <div class="media-aside align-self-start">
                            <img :src="user.urlImagen" alt="graphic" width="30" height="30" id="comercios-images">
                          </div>
                          <div class="media-body">
                              <div class="data-info">
                                  <h6 class="" id="first-letter">{{user.nomEmpresa.toLowerCase()}}</h6>
                                  <!-- <p class="">45 min ago</p> -->
                              </div>

                              <div class="icon-status">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-x"
                                  >
                                      <line x1="18" y1="6" x2="6" y2="18"></line>
                                      <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </a>
              </li>
              <div>
                <div v-for="item in kb" :key="item.id" @click="CambioComercio(item)">
                  <li role="presentation"><hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" /></li>
                  <li role="presentation">
                      <a class="dropdown-item" >
                          <div class="media">
                              <div class="media-aside align-self-start">
                                  <!-- <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-heart"
                                  >
                                      <path
                                          d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
                                      ></path>
                                  </svg> -->
                                <img :src="item.graphic" alt="graphic" width="30" height="30" id="comercios-images">
                              </div>
                              <div class="media-body">
                                  <div class="data-info">
                                      <h6 id="first-letter"> {{item.title.toLowerCase() }}</h6>
                                      <!-- <p>8 hrs ago</p> -->
                                  </div>
                                  <div class="icon-status">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="feather feather-x"
                                      >
                                          <line x1="18" y1="6" x2="6" y2="18"></line>
                                          <line x1="6" y1="6" x2="18" y2="18"></line>
                                      </svg>
                                  </div>
                              </div>
                          </div>
                      </a>
                  </li>
                </div>
              </div> 
            </ul>
          </div>
        </a>
      </div>

      <div v-if="user.codSucursal!='0'" class="dropdown nav-item message-dropdown btn-group">
        <li class="btn dropdown-toggle btn-icon-only nav-link"><a href="/sucursales"> {{user.nomSucursal}} </a></li>
      </div>
      
      <div class="dropdown nav-item message-dropdown btn-group"></div>
      <div class="dropdown nav-item message-dropdown btn-group"></div>

    </div>

</template>

<script setup>
  import '@/assets/sass/pages/pagos.scss';  
  // import { useStore } from 'vuex';
  // import { onMounted, ref } from 'vue';

  //Atributos de clase
  // var kb;
  // const store = useStore();
  //Objetos  
  // const user     = Object.assign({}, store.getters.user);  

  // var comercios;

  // onMounted(() => {
  //   comerciosUser()
  //   drowdownItems()
  //   console.log(kb)
  // });


  // function cambiarSucursal(){

  //   store.dispatch('reload_page', true);
  //   // console.log('State changing, ',store.getters.reloadPage)
  //   router.replace("/sucursales");

  // };

  // function CambioComercio(item){

  //   // console.log('Selecciono la empresa, ', item.title, ' con Codempresa: ', item.codEmpresa, ' y tiene este numero de sucursales:', item.sucursales)
  //   const data = {
  //     codEmpresa: item.codEmpresa,
  //     nomEmpresa: item.title,
  //     sucursales: item.sucursales,
  //     urlImagen:  item.graphic
  //   }
    
  //   store.dispatch('addComercioActual', data)
    
  //   if (store.getters.user.codSucursal == -1) {

  //     var sucursalesComercioResponse = axiosWS3.sucursalesComercioResponse(data.codEmpresa);
  //     sucursalesComercioResponse.then( response => {
  //       //si la respuesta es positiva
  //       if (response.data.status === true) {
  //         store.dispatch('addSucursales', response.data.comercios)
  //         store.state.user.codSucursal = -1
  //         store.state.user.nomSucursal = 'Seleccione Sucursal'
  //         drowdownItems();
  //         router.replace('/pass_sucursales')
  //       }
  //       else {
  //         store.state.user.codSucursal = 0
  //         store.state.user.nomSucursal = 'Sin Sucursal, comuníquese con proveedor'
  //       }
  //     })
  //   }

  //   else {
  //     // console.log(`Presiono la carta con ID: ${id}, su nomEmpressa: ${data.nomEmpresa}, su codEmpresa ${data.codEmpresa} y sucursales en ${data.sucursales}`)
  //     drowdownItems();
  //     router.push('/Inicio')
  //   }

  // };

  // function drowdownItems(){
      
  //   kb = []
    

  //   for (let i = 0; i < comercios.length; i++) {

  //     if (comercios[i].codEmpresa != user.codEmpresa){    
        
  //       const item = {
  //         id          : i,
  //         title       : comercios[i].nomEmpresa,
  //         graphic     : comercios[i].urlImagen,
  //         sucursales  : comercios[i].sucursales,
  //         codEmpresa  : comercios[i].codEmpresa,
  //         url         : '/pagos/PagoQR'
  //       }

  //       kb.push(item)
  //     }
  //   }

  // }

  // function comerciosUser() {
    
  //   comercios = [];

  //   for (let i = 0; i < store.getters.comercios.length; i++) {
      
  //     const comercio = {
  //       codEmpresa : store.getters.comercios[i].codEmpresa,
  //       nomEmpresa : store.getters.comercios[i].nomEmpresa,
  //       urlImagen:   store.getters.comercios[i].urlImagen,
  //       type :       store.getters.comercios[i].type,
  //       sucursales:  store.getters.comercios[i].sucursales
  //     }

  //     comercios.push(comercio)
  //   }
  // }
</script>

<script>
import { useStore } from 'vuex';
  export default {
    data () {
      
      let store = useStore();
      
      const user = store.getters.user;
      let comercios = store.getters.comercios;
      
      var kb = [] 
      kb= this.kbAdd(user, comercios);
      
      return {
        user,
        kb
      }
    },
    methods: {

      CambioComercio(item){

        // console.log('Selecciono la empresa, ', item.title, ' con Codempresa: ', item.codEmpresa, ' y tiene este numero de sucursales:', item.sucursales)
        const data = {
          codEmpresa: item.codEmpresa,
          nomEmpresa: item.title,
          sucursales: item.sucursales,
          urlImagen:  item.graphic
        }
        
        store.dispatch('addComercioActual', data)
        
        if (store.getters.user.codSucursal == -1) {

          var sucursalesComercioResponse = axiosWS3.sucursalesComercioResponse(data.codEmpresa);
          sucursalesComercioResponse.then( response => {
            //si la respuesta es positiva
            if (response.data.status === true) {
              store.dispatch('addSucursales', response.data.comercios)
              store.state.user.codSucursal = -1
              store.state.user.nomSucursal = 'Seleccione Sucursal'
              router.replace('/pass_sucursales')
            }
            else {
              store.state.user.codSucursal = 0
              store.state.user.nomSucursal = 'Sin Sucursal, comuníquese con proveedor'
            }
          })
        }
        else {
          // console.log(`Presiono la carta con ID: ${id}, su nomEmpressa: ${data.nomEmpresa}, su codEmpresa ${data.codEmpresa} y sucursales en ${data.sucursales}`)
          const user = store.getters.user;
          const comercios = store.getters.comercios;
          kb= this.kbAdd(user, comercios);
          router.replace('/')
        }
      },

      cambiarSucursal(){
        store.dispatch('reload_page', true);
        // console.log('State changing, ',store.getters.reloadPage)
        router.replace("/sucursales")
      },

      kbAdd( user, comercios){
        var kbb=[]
        for (let i = 0; i < comercios.length; i++) {
          const idd = i
          
          if (comercios[i].codEmpresa != user.codEmpresa){    
            const item = {
              id          : idd,
              title       : comercios[i].nomEmpresa,
              graphic     : comercios[i].urlImagen,
              sucursales  : comercios[i].sucursales,
              // graphic     : require('@/assets/images/pages/kupi.png'),
              codEmpresa  : comercios[i].codEmpresa,
              url         : '/pagos/PagoQR'
            }
            kbb.push(item)
          }
        }
        return kbb
      }
    },
  }
</script>


<style>
  #header-template {
    margin-left: 200px;
  }
  #first-letter {
    text-transform:capitalize;
  }
</style>