////////////////////////////////////////////////
/// Los Getters son acciones realizadas en los estados para obtener informacion 
/// acerca de ellos
////////////////////////////////////////////////

const getters = {
  
  layout(state) {
      return state.layout;
  },
  is_logged(state) {
      return state.is_logged;
  },
  user(state) {
    return state.user;
  },
  comercios(state) {
    return state.comercios;
  },
  sucursales(state) {
    return state.sucursales;
  },
  reloadPage(state) {
    return state.reloadPage;
  },
  pagoPin(state) {
    return state.pagoPin;
  },
  pagoQR(state) {
    return state.pagoQR;
  }
}

export default getters
