<template>
    <div class="layout-px-spacing">
      <teleport to="#breadcrumb">
          <ul class="navbar-nav flex-row">
              <li>
                  <div class="page-header">
                      <nav class="breadcrumb-one" aria-label="breadcrumb">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="javascript:;">Users</a></li>
                              <li class="breadcrumb-item active" aria-current="page"><span>Mi cuenta</span></li>
                          </ol>
                      </nav>
                  </div>
              </li>
          </ul>
      </teleport>

      <div class="row layout-spacing">
        <!-- Content -->
        <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
          <div class="user-profile layout-spacing">
            <div class="panel">
              <div class="panel-body">
                <div class="d-flex justify-content-between">
                  <h3 class="">Perfil</h3>
                  <div class="mt-2 edit-profile">
                    <fa :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" :icon="['fas', 'user']" class="ml-2"> </fa>
                  </div>
                </div>
                  <div class="text-center user-info">
                    <p class=""> {{user.nomUsuario }} {{user.apeUsuario}}</p>
                  </div>
                  <div class="user-info-list">
                    <div class="">
                      <ul class="contacts-block list-unstyled">
                        <li class="contacts-block__item">
                          <a href="https://s3.amazonaws.com/app.kupi.s3/tratamientoDatos/Politica_Tratamiento_de_Datos_KUPI_2018.pdf" target="_blank" class="btn btn-primary mt-2 m-lg-2">  Ver Términos y Condiciones  </a>
                        </li>
                      </ul>
                    </div>
                  </div>
              </div>
            </div>
          </div>
      </div>

        <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
            <div class="skills layout-spacing">
              <div class="panel">
                  <div class="panel-body">
                      <h3 class="">Datos Personales</h3>
                      <div class="row">
                        <div class="col-4">
                          <p>  Cédula </p>
                          <div class="form-group">
                            <div class="input-group">
                              <input disabled type="text" class="form-control" placeholder="" v-model="user.numDocumento"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <p>  Telefono de Notificaciones </p>
                          <div class="form-group">
                            <div class="input-group">
                              <input disabled type="text" class="form-control" placeholder="" v-model="telUsuario"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <p>  Email </p>
                          <div class="form-group">
                            <div class="input-group">
                              <input disabled type="text" id="emaCard" class="form-control" placeholder="" v-model="emaUsuario"/>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>

            <div class="bio layout-spacing">
              <div class="panel">
                <div class="panel-body">
                  <h3 class=""> 
                    <fa :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" :icon="['fas', 'edit']" class="ml-2"> </fa>
                    Editar Información
                  </h3>
                    
                  <div class="row">
                    <div class="col-4">
                      <p id="enumCard">  Fecha de Nacimiento </p>
                      <div class="form-group">
                        <div class="input-group">
                          <input type="text" v-model="fecNacimiento" class="form-control" v-maska="'####/##/##'" placeholder="AAAA/MM/DD" />
                          <!-- <flat-pickr v-model="fecNacimiento" :config="config" class="form-control input-group flatpickr active"></flat-pickr> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <p id="enumCard">  Género </p>
                      <div class="form-group">
                        <div class="input-group">
                          <!-- Select menu -->
                          <select v-model="sexUsuario" class="mb-4 form-select w-100">
                            <option value="0">Hombre</option>
                            <option value="1">Mujer</option>
                            <option value="-1">Otro</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <p id="enumCard">  Ciudad </p>
                      <div class="form-group">
                        <div class="input-group">
                          <multiselect v-model="ciudad"  :options="ciudades" label="nomCiudad" :searchable="true" :preselect-first="false" :placeholder="nomCiudad" selected-label="" select-label="" deselect-label=""></multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <!-- INICIO MSJBOX -->
                  <div class="row" v-if="msjBox.visible === true">
                    <div :class="msjBox.status == true ? 'col-md-12 alert alert-light-success alert-dismissible  ml-5' : 'col-md-12 alert alert-light-danger alert-dismissible  ml-5'">
                      <button type="button" class="close" aria-label="Close" @click="resetbox">×</button>
                      <div class="media">
                        <div class="media-aside align-self-start">
                          <fa class="fa-25" :icon="['fas', 'exclamation-triangle']"> </fa>
                        </div>
                        <div class="media-body">
                          <div class="alert-text ms-2"> <strong>{{msjBox.message}} </strong></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                      <button class="btn btn-primary rounded-4 w-100" @click="actualizarInformacion"> Actualizar </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <br>
            <!-- ------------------------------------ -->
            <!-- EDITAR CLAVE -->
            <!-- -------------------------------- -->
            <div class="bio layout-spacing">
              <div class="panel">
                <div class="panel-body">
                  <h3 class=""> 
                    <fa :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" :icon="['fas', 'edit']" class="ml-2"> </fa>
                     Cambiar Clave
                  </h3>
                    
                  <div class="row">
                    <div class="col-md-12">
                      
                      <p id="enumCard">  Clave Actual </p>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-text" :id="$store.state.dark_mode == 'light' ? '' : 'span-input-group-text'">
                            <fa :icon="['fas', 'lock']"> </fa>
                          </span>
                          <input type="text" class="form-control" placeholder="Ingrese su clave actual" v-model="passwordOld"/>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      
                      <p id="enumCard">  Nueva Clave </p>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-text" :id="$store.state.dark_mode == 'light' ? '' : 'span-input-group-text'">
                            <fa :icon="['fas', 'mobile-screen']"> </fa>
                          </span>
                          <input type="text" class="form-control" placeholder="Ingrese su nueva clave" v-model="password"/>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      
                      <p id="enumCard">  Comfirmar Clave </p>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-text" :id="$store.state.dark_mode == 'light' ? '' : 'span-input-group-text'">
                            <fa :icon="['fas', 'mobile-screen']"> </fa>
                          </span>
                          <input type="text" class="form-control" placeholder="Comfirme su clave" v-model="passwordConfirm"/>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- INICIO MSJBOX -->
                  <div class="row" v-if="msjBox2.visible === true">
                    <div :class="msjBox2.status == true ? 'col-md-12 alert alert-light-success alert-dismissible  ml-5' : 'col-md-12 alert alert-light-danger alert-dismissible  ml-5'">
                      <button type="button" class="close" aria-label="Close" @click="resetbox2">×</button>
                      <div class="media">
                        <div class="media-aside align-self-start">
                          <fa class="fa-25" :icon="['fas', 'exclamation-triangle']"> </fa>
                        </div>
                        <div class="media-body">
                          <div class="alert-text ms-2"> <strong>{{msjBox2.message}} </strong></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>

                  <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                      <div class="btn btn-primary rounded-4 w-100" @click="changePassVue"> Cambiar </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>


    </div>
    <codPerfilJWT></codPerfilJWT>
    <logOut></logOut>
</template>

<script setup>
  //EStilos
  import '@/assets/sass/pages/pagos.scss';  
  import '@/assets/sass/users/user-profile.scss';
  import '@/assets/sass/scrollspyNav.scss';

  import { onMounted, ref } from 'vue';
  //flatpickr
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import '@/assets/sass/forms/custom-flatpickr.css';
  import { Spanish } from "flatpickr/dist/l10n/es.js";
  import Multiselect from '@suadelabs/vue3-multiselect';
  import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';


  //Atributos del scrip o default configs
  const config = {
    wrap: true, // set wrap to true only when using 'input-group'
    maxDate: Date.now(),
    firstDayOfWeek : 1,
    locale: Spanish, // locale for this instance only          
  };

  var msjBox = ref({ visible: false, status: false, message: ''});
  var msjBox2 = ref({ visible: false, status: false, message: ''});
  let user = ref(store.getters.user);
  var ciudad          =  ref('');
  var ciudades        = ref([]);
  var numDocumento    = ref(0);
  var telUsuario      = ref('');
  var emaUsuario      = ref('');
  var nomCompleto     = ref('');
  var nomCiudad       = ref();
  var codCiudad       = ref('');
  var sexUsuario      = ref('');
  var fecNacimiento   = ref('');
  var password        = ref('');
  var passwordOld     = ref('');
  var passwordConfirm = ref('');

  onMounted(() => {
    getDataUserVue()
    ciudadesColombia()
  });

  function getDataUserVue () {

    var data = {
      codUsuario: store.getters.user.codUsuario
    }

    var getDataUserVueResponse = axiosWS3.getDataUserVue(data);
    getDataUserVueResponse.then(res => {
      const au = res.data
      if (au.status === true) 
      {
        numDocumento.value = au.numDocumento
        telUsuario.value = au.telUsuario
        emaUsuario.value= au.emaUsuario
        nomCompleto.value = au.nomCompleto
        nomCiudad.value = au.nomCiudad
        codCiudad.value = au.codCiudad
        sexUsuario.value = au.sexUsuario
        fecNacimiento.value = au.fecNacimiento
      } 
      // else {
        // console.log('Por favor, realice el log nuevamente')
      // }
    })
  
  };

  
  function ciudadesColombia ()  {

    var data = {
      codUsuario: store.getters.user.codUsuario
    }

    var getCiudadesResponse = axiosWS.getCiudades(data);

    getCiudadesResponse.then(res => {
      const au = res.data.ciudades
      ciudades.value = []
      
      
      if (res.data.status == true) {
        for (let i = 0; i < au.length; i++) {
          // options2.push(au[i])
          let ciudad = {
            nomCiudad: au[i].nomCiudad,
            codCiudad: au[i].codCiudad
          }
          
          ciudades.value.push(ciudad)
        }
      } 
    })


    // ciudades = options2; 

    // console.log(ciudades)

    // return options2;
  };

  function resetbox () {
    msjBox.value.visible = false;
    msjBox.value.status = false;
    msjBox.value.message = '';
  }

  function resetbox2 () {
    msjBox2.value.visible = false;
    msjBox2.value.status = false;
    msjBox2.value.message = '';
  }

  function actualizarInformacion () {
    // console.log('Actualizar')

    if(ciudad.value=='')
    {

      var data = {
        codUsuario:     store.getters.user.codUsuario,
        fecNacimiento:  fecNacimiento.value,
        codCiudad:      codCiudad.value,
        sexUsuario:     sexUsuario.value
      }
      
        
      var actUsrSelfVueResponse = axiosWS3.actUsrSelfVue(data);

      actUsrSelfVueResponse.then(res => {
        
        if (res.data.status === true) {
          msjBox.value.visible= true;
          msjBox.value.status= true;
          msjBox.value.message= res.data.message;
        } else {

          msjBox.value.visible= true;
          msjBox.value.status= false;
          msjBox.value.message= res.data.message;

        }
      })

    }

    else 
    {

      var data = {
        codUsuario:     store.getters.user.codUsuario,
        fecNacimiento:  fecNacimiento.value,
        codCiudad:      ciudad.value.codCiudad,
        sexUsuario:     sexUsuario.value
      }
      
      var actUsrSelfVueResponse = axiosWS3.actUsrSelfVue(data);

      actUsrSelfVueResponse.then(res => {

        if (res.data.status === true) {
          
          msjBox.value.visible= true;
          msjBox.value.status= true;
          msjBox.value.message= res.data.message;

        } else {
          msjBox.value.visible= true;
          msjBox.value.status= false;
          msjBox.value.message= res.data.message;
        }
      })


    }
    

  };
  
  function changePassVue () {
    
    resetbox2()

    var data = {
      codUsuario: store.getters.user.codUsuario,
      password: password.value,
      passwordOld: passwordOld.value,
      passwordConfirm: passwordConfirm.value
    }

    var changePassVueResponse = axiosWS3.changePassVue(data);
    changePassVueResponse.then(res => {
      const au = res.data
      if (au.status === true) {
        msjBox2.value.visible = true;
        msjBox2.value.status = true;
        msjBox2.value.message = au.message;
      } 
      else {
        msjBox2.value.visible = true;
        msjBox2.value.status = false;
        msjBox2.value.message = au.message;
      }
    })
  };   

</script>

<script>


  import logOut from '@/views/logOut/logOut.vue';
  import codPerfilJWT from '@/views/kupiComercios/codPerfil/codPerfilJWT.vue';

  export default {
    components: {
      logOut,
      codPerfilJWT
    }
  }
</script>

<style>
 #emaCard {
  text-align: justify;
  font-size: 12px;
 }

 #enumCard {
  text-align: justify;
  font-size: 13px;
 }
</style>
