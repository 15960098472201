
import axios from "axios";

//Defino el entorno si es producción o desarrollo
class AxiosW3 {
  
  baseURLws3;

  constructor( ) {
    //produccion
    this.baseURLws3 = 'https://kupi.com.co/ws3/public';
    //STAG
    // this.baseURLws = 'https://kupi.com.co/ws3/public/';
  }
  
  ///////////////////////////////////////////////////////////
  // STAR USER SERVICES
  //////////////////////////////////////////////////////////

  //Login
  loginAppResponse = async(user,pass) => {
    
    const urlrequest = `${this.baseURLws3}/loginApp`;

    const body = {
        user: user,
        pass: pass
    };

    const result = await axios.post( urlrequest, body ). then( res => {
      return res;
    }).catch(err => {
      return err;
    })

    return result;
  }


  // olvido contraseña
  forgotPass = async(user) => {

    const urlrequest = `${this.baseURLws3}/user/forgot`;

    const body = {
      user: user,
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;

  }

  
  // Cantidad de comercios del usuario
  comerciosUsuarioResponse = async(codUsuario) => {

    const urlrequest = `${this.baseURLws3}/comerciosUsuarioVue`;

    const body = {
      codUsuario: codUsuario,
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;

  }


  //cantidad de sucursales por comercio
  sucursalesComercioResponse = async(codEmpresa) => {

    const urlrequest = `${this.baseURLws3}/sucursalesComercioVue`;

    const body = {
      codEmpresa: codEmpresa,
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;

  }


  //Listar ventas vue
  getDataUserVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/getDataUserVue`;

    const body = {
      codUsuario :    data.codUsuario
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }


  //Actualizar usuario
  actUsrSelfVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/actUsrSelfVue`;

    const body = {
      codUsuario :    data.codUsuario,
      fecNacimiento:  data.fecNacimiento,
      codCiudad:      data.codCiudad,
      sexUsuario:     data.sexUsuario
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }


   //Change Pass vue
   changePassVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/changePassVue`;

    const body = {
      codUsuario :      data.codUsuario,
      password:         data.password,
      passwordOld:      data.passwordOld,
      passwordConfirm:  data.passwordConfirm
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  ///////////////////////////////////////////////////////////
  // END USER SERVICES
  //////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // PAY SERVICES
  //////////////////////////////////////////////////////////
  consultaSaldoVue = async(numDocumento) => {

    const urlrequest = `${this.baseURLws3}/consultaSaldoVue`;

    const body = {
      numDocumento: numDocumento,
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;

  }

  //Registrar pago pin
  regPayPinAppVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/regPayPinAppVue`;

    const body = {
      codEmpresa        : data.codEmpresa,
      codSucursal       : data.codSucursal,
      codVendedor       : data.codVendedor,
      valTransaccion    : data.valTransaccion,
      refTransaccion    : data.refTransaccion,
      numIdentificacion : data.numIdentificacion
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  //Anular Pago Pin
  anularTrVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/anularTrVue`;

    const body = {
      codConvenio       : data.codConvenio,
      codVendedor       : data.codVendedor,
      numTransaccion    : data.numTransaccion
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  //Reenviar Pin voz
  resendPinVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/resendPinVue`;

    const body = {
      numTransaccion    : data.numTransaccion
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  //Reenviar Pin
  resendPinVozVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/resendPinVozVue`;

    const body = {
      numTransaccion    : data.numTransaccion
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  // doPayPinAppVue (realizar pago)
  doPayPinAppVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/doPayPinAppVue`;

    const body = {
      codEmpresa :        data.codEmpresa,
      codVendedor :       data.codVendedor,
      pinTransaccion :    data.pinTransaccion,
      numIdentificacion : data.numIdentificacion
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  //Registrar QR
  regPayQrAppVueVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/regPayQrAppVue`;

    const body = {
      codEmpresa:     data.codEmpresa,
      codSucursal:    data.codSucursal,
      codVendedor:    data.codVendedor,
      valTransaccion: data.valTransaccion,
      refTransaccion: data.refTransaccion
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  //GetInfoQR
  getInfoQrVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/getInfoQrVue`;

    const body = {
      numTransaccion:   data.numTransaccion,
      codVendedor :     data.codVendedor
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }


  //Listar ventas vue
  listarVentasVue = async(data) => {

    const urlrequest = `${this.baseURLws3}/ListarVentasVue`;

    const body = {
      codEmpresa :    data.codEmpresa,
      codSucursal :   data.codSucursal,
      codUsuario :    data.codUsuario,
      fechaInicial :  data.fechaInicial,
      fechaFinal :    data.fechaFinal
    };

    const result = await axios.post( urlrequest, body). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  



  ///////////////////////////////////////////////////////////
  // END PAY SERVICES
  //////////////////////////////////////////////////////////

  
}


export default AxiosW3