<template>

  <!-- Datos del pagues -->
  <teleport to="#breadcrumb">
    <ul class="navbar-nav flex-row">
      <li>
        <div class="page-header">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/Inicio"> Ir a Home </a></li>
              <!-- <li class="breadcrumb-item active" aria-current="page"><span>Pago con Pin</span></li> -->
            </ol>
          </nav>
        </div>
      </li>
    </ul>
    <cambiar></cambiar>
  </teleport>

  <regPagoQR   v-if="$store.getters.pagoQR.id_transaccion===''"></regPagoQR>
  <waitPagoQR  v-if="$store.getters.pagoQR.pagoProcesado ===2"></waitPagoQR>
  <trExitosa   v-if="$store.getters.pagoQR.pagoProcesado ===3"></trExitosa> 
  <codPerfilJWT></codPerfilJWT>
  <logOut></logOut>
</template>

<script>

  import regPagoQR from './components/pagoQR/regPagoQR.vue';
  import waitPagoQR from './components/pagoQR/waitPagoQR.vue';
  import trExitosa from './components/pagoQR/trExitosaQR.vue';
  import logOut from '@/views/logOut/logOut.vue';
  import cambiar from '@/views/kupiComercios/pagos/components/cambiarEmpresa.vue';
  import codPerfilJWT from '@/views/kupiComercios/codPerfil/codPerfilJWT.vue';


  export default {
    components: {
      regPagoQR,
      waitPagoQR,
      trExitosa,
      logOut,
      codPerfilJWT,
      cambiar
    },
  }
</script>