
import axios from "axios";

//Defino el entorno si es producción o desarrollo
class AxiosCalls {
  
  baseURLws;

  constructor( ) {
    //produccion
    this.baseURLws = 'https://kupi.com.co/ws';
    //STAG
    // this.baseURLws = 'https://kupi.com.co/ws3/public/';
  }


  // WS //
  getCategorias = async(codCiudad) => {
    

    const urlrequest = `${this.baseURLws}/getCategorias`;    

    //submit.prevent.
    
    // 'Content-Type': 'application/x-www-form-urlencoded',

    const paramsOptions = {
    };

    
    const headerOptions = {
      'Access-Control-Allow-Origin' : '*',
      'codCiudad': codCiudad
    };
    

    const dataForm = {
      params: paramsOptions,
      headers: headerOptions
    };

    axios.get( urlrequest, dataForm);


  }

  /////////////////////////////////////////////////////////
  /////////// GET CIUDADES ///////////////////////////////
  ///////////////////////////////////////////////////////
  getCiudades = async(data) => {

    const urlrequest = `${this.baseURLws}/getCiudades`;

    const body = {
      codUsuario :    data.codUsuario
    };

    const result = await axios.get( urlrequest). then( res => {
      
      return res;

    }).catch(err => {

      return err; 

    })

    return result;
  }

  /////////////////////////////////////////////////////////
  /////////// GET CIUDADES ///////////////////////////////
  ///////////////////////////////////////////////////////
  

}


export default AxiosCalls