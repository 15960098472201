<template>
    <div class="layout-px-spacing">
        <div class="coming-soon">
            <div class="coming-soon-container">
                <div class="coming-soon-cont">
                    <div class="coming-soon-wrap">
                        <div class="coming-soon-content">
                              <h4 id='sep' class="">Hola {{user.nomUsuario}}</h4>
                              <p class="">Nos encanta tenerte de vuelta, por favor, seleccione el comercio al cual desea ingresar:</p>

                              <div id="comercios-cards-align">
                                  <div v-for="item in kb" :key="item.id" @click="ComercioModal(item.id, item)">
                                      <div id="comercios-cards">
                                        <p> <img :src="item.graphic" alt="graphic" width="80" height="80" id="comercios-images"> </p> 
                                        <span class="text" id="first-letter"> {{item.title.toLowerCase() }}</span>
                                      </div>
                                  </div>
                              </div> 

                              <h3> Síguenos y no te pierdas nada de nuestro universo Kupi: </h3>

                              <ul class="social list-inline">
                                  <li class="list-inline-item">
                                    <a class="" href="https://www.linkedin.com/company/kupigroup/" target="_blank">
                                      <div class="icon-container"><i data-feather="linkedin"></i><span class="icon-name"></span></div>
                                    </a>
                                  </li>
                                  <li class="list-inline-item">
                                    <a class="" href="https://www.facebook.com/appkupi" target="_blank">
                                      <div class="icon-container"><i data-feather="facebook"></i><span class="icon-name"></span></div>
                                    </a>
                                  </li>
                                  <li class="list-inline-item">
                                    <a class="" href="https://www.instagram.com/appkupi/?hl=es" target="_blank">
                                      <div class="icon-container"><i data-feather="instagram"></i><span class="icon-name"></span></div>
                                    </a>
                                  </li>
                                  <li class="list-inline-item">
                                    <a class="" href="https://kupi.com.co/" target="_blank">
                                      <div class="icon-container"><i data-feather="at-sign"></i><span class="icon-name"></span></div>
                                    </a>
                                  </li>
                              </ul>

                              <!-- Terminos y condiciones -->
                              <p class="terms-conditions">
                                Copyright © 2022 Elaborado por <a href="https://kupi.com.co/" target="_blank">Kupi Group SAS</a > – Todos los derechos reservados:
                                tomate un tiempo y lee nuestros terminos de <a href="javascript:void(0);">Privacidad </a> y <a href="javascript:void(0);"> Condiciones</a>.
                              </p>

                          </div>
                    </div>
                </div>
                <div class="coming-soon-image">
                    <div class="l-image">
                        <div class="img-content">
                            <img src="@/assets/images/mindset.svg" alt="coming_soon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    //estilos
    import '@/assets/sass/pages/coming-soon/style.scss';
    //librerias 
    import { useStore } from 'vuex';
    import feather from 'feather-icons';


    //Variables
    let store = useStore();
    let user = store.getters.user;
    let is_logged = store.getters.is_logged;

    if(is_logged != true) {
      router.replace('/auth/login-boxed');
    }
    else
    {
      if(user.cantComercios<1)
      {
        // console.log('tiene mas de un comercio')
      }
      // si el usuario tiene mas de un comercio
      else
      {
        onMounted(() => {
          feather.replace();
        });
      }
    }
</script>

<script>
import { useStore } from 'vuex';
export default {
  data () {
    
    let store = useStore();
    let comercios = store.getters.comercios;
    
    const kb = []
    
    for (let i = 0; i < comercios.length; i++) {
      const idd = i
      const item = {
        id          : idd,
        title       : comercios[i].nomEmpresa,
        graphic     : comercios[i].urlImagen,
        sucursales  : comercios[i].sucursales,
        // graphic     : require('@/assets/images/pages/kupi.png'),
        codEmpresa  : comercios[i].codEmpresa,
        url         : '/pagos/PagoQR'
      }
      kb.push(item)
    }

    return {
      kb
    }
  },
  methods: {
    ComercioModal (id, item) {

      const data = {
        codEmpresa: item.codEmpresa,
        nomEmpresa: item.title,
        sucursales: item.sucursales,
        urlImagen:  item.graphic
      }
      
      store.dispatch('addComercioActual', data)
      
      if (store.getters.user.codSucursal == -1) {

        var sucursalesComercioResponse = axiosWS3.sucursalesComercioResponse(data.codEmpresa);
        sucursalesComercioResponse.then( response => {
          //si la respuesta es positiva
          if (response.data.status === true) {
            store.dispatch('addSucursales', response.data.comercios)
            store.state.user.codSucursal = -1
            store.state.user.nomSucursal = 'Seleccione Sucursal'
            router.replace('/sucursales')
          }
          else {
           store.state.user.codSucursal = 0
           store.state.user.nomSucursal = 'Sin Sucursal, comuníquese con proveedor'
          }
        })
      }
      else {
        // console.log(`Presiono la carta con ID: ${id}, su nomEmpressa: ${data.nomEmpresa}, su codEmpresa ${data.codEmpresa} y sucursales en ${data.sucursales}`)
        router.push('/Inicio')
      }

      
    }
  },
}
</script>


<style>
  #rows{
    margin: 0px 60px
  }

  #first-letter {
    text-transform:capitalize;
  }

  #sep {
    margin-bottom: 5px;
  }
</style>