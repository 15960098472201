<template>
    <div class="layout-px-spacing">
        <div class="coming-soon">
          <div class="coming-soon-container">

            <!-- TEXTO SUCURSAL -->
            <div class="coming-soon-cont">
              <div class="coming-soon-wrap">
                <div class="coming-soon-content">

                  <h4 id='sep' class="">Hola {{user.nomUsuario}}</h4>
                  <br>
                  <p>Detectamos que tu comercio {{user.nomEmpresa}} tiene dos o mas sucursales, por favor selecciona tu sucursal:</p>

                  <!-- MULTIIISELECET -->
                  <div class="panel-body">
                    <multiselect v-model="sucursal"  :options="options2" label="nomSucursal" :searchable="true" :preselect-first="false" placeholder="Seleccione una sucursal" selected-label="" select-label="" deselect-label=""></multiselect>
                  </div>
                  <br>
                  <span class="text-danger text-sm"> 
                    <b class="text-danger"> {{boldProblema}}</b>
                  </span>

                  <br>
                  <button class="btn btn-success" id="comercios-cards-align" @click="SucursalModal()"> Comfirmar </button>
                  
                  <h3> Síguenos y no te pierdas nada de nuestro universo Kupi: </h3>

                  <ul class="social list-inline">
                      <li class="list-inline-item">
                        <a class="" href="https://www.linkedin.com/company/kupigroup/" target="_blank">
                          <div class="icon-container"><i data-feather="linkedin"></i><span class="icon-name"></span></div>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="" href="https://www.facebook.com/appkupi" target="_blank">
                          <div class="icon-container"><i data-feather="facebook"></i><span class="icon-name"></span></div>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="" href="https://www.instagram.com/appkupi/?hl=es" target="_blank">
                          <div class="icon-container"><i data-feather="instagram"></i><span class="icon-name"></span></div>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="" href="https://kupi.com.co/" target="_blank">
                          <div class="icon-container"><i data-feather="at-sign"></i><span class="icon-name"></span></div>
                        </a>
                      </li>
                  </ul>

                  <!-- Terminos y condiciones -->
                  <p class="terms-conditions">
                    Copyright © 2022 Elaborado por <a href="https://kupi.com.co/" target="_blank">Kupi Group SAS</a > – Todos los derechos reservados:
                    tomate un tiempo y lee nuestros terminos de <a href="javascript:void(0);">Privacidad </a> y <a href="javascript:void(0);"> Condiciones</a>.
                  </p>

                </div>
              </div>
            </div>

            <!-- IMAGEN KUPI -->
            <div class="coming-soon-image">
              <div class="l-image">
                <div class="img-content">
                  <img src="@/assets/images/logoKupi.png" alt="coming_soon" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script setup>
    //estilos
    import '@/assets/sass/pages/coming-soon/style.scss';
    import '@/assets/sass/scrollspyNav.scss';
    import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
    //librerias 
    import { useStore } from 'vuex';
    import feather from 'feather-icons';
    import { onMounted, ref } from 'vue';
    import Multiselect from '@suadelabs/vue3-multiselect';


    let store = useStore();
    let reloadPage = store.getters.reloadPage;
    let user = store.getters.user;
    let is_logged = store.getters.is_logged;  
    let sucursales = store.getters.sucursales;    
    let options1=[];
    let options2=[];
    var sucursal=ref();
    let boldProblema = ref('');


    for (let i = 0; i < sucursales.length; i++) {
      const nomSucursal = sucursales[i].nomSucursal;
      options1.push(nomSucursal);
      options2.push(sucursales[i])
    }
    

    if(is_logged != true) {
      router.replace('/auth/login-boxed');
    }
    else
    {
      if(user.cantComercios<1)
      {
        // console.log('tiene mas de un comercio')
      }
      // si el usuario tiene mas de un comercio
      else
      {
        onMounted(() => {

          if(reloadPage == true) {
            store.dispatch('reload_page', false)
            // console.log('State changing, ',store.getters.reloadPage)
            location.reload()
          }
          // location.reload();
          feather.replace();
        });
      }
    }


    function SucursalModal () {
      
      boldProblema.value ="";
      // console.log(sucursal)
      if(sucursal.value){ 
        const data = {
          nomSucursal : sucursal.value.nomSucursal,
          codSucursal: sucursal.value.codSucursal
        }
        // console.log(data)
        store.dispatch('addSucursalActual', data)

        // console.log(`Los valores de Set son nomEmpressa: ${store.getters.user.nomEmpresa}, su codEmpresa ${store.getters.user.codEmpresa} y sucursales en ${store.state.user.nomSucursal} codSucursal ${store.state.user.codSucursal}`)
        router.replace('/')
      }
      else
      {
        boldProblema.value ="Seleccione una sucursal para continuar";
      }
    }
</script>


<style>
  #rows{
    margin: 0px 60px
  }

  #first-letter {
    text-transform:capitalize;
  }

  #sep {
    margin-bottom: 5px;
  }
</style>