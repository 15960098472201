<template>
  
 <div class="layout-px-spacing">
    <div class="pagos container statbox box box-shadow">
      <div class="out-header-wrapper">
      </div>
      <div class="row">
        <div class="col-md-1"></div>

        <div class="col-md-10">
          
          <div class="hd-tab-section" align="center">
            <div class="accordion">
              <div class="card">
                
                  <div :id="$store.state.dark_mode == 'light' ? '' : 'color-card'" class="card-header"  role="tab" >
                    <div class="mb-0">
                      <div
                        role="menu"
                        data-bs-target="#collapse-hd-statistics-1"
                        aria-expanded="true"
                        aria-controls="collapse-hd-statistics-1"
                      >
                        <h5 :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" style="text-align:left;">
                          <fa :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" :icon="['fas', 'envelope']" class="ml-2"> </fa>
                          Pago Con Pin ( SMS )( Mensaje de texto)
                        </h5>
                      </div>
                    </div>
                  </div>

                <div id="collapse-hd-statistics-1">
                    <div class="card-body"> 

                      <!-- INICIO MSJBOX2 -->
                      <div class="row" v-if="msjBox2.visible === true">
                        
                        <div v-if="msjBox2.status === true" class="col-md-11 alert alert-light-success alert-dismissible border-0 mb-4 " role="alert">
                          <strong>{{msjBox2.message}} </strong> 
                          <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                        </div>
                        <div v-else class="col-md-11 alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                          <strong>{{msjBox2.message}} </strong>
                          <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-11">
                          <div class="form-group">
                            <div class="input-group">
                              <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                <fa :icon="['fas', 'dollar-sign']"> </fa>
                              </span>
                              <input id="inputtext" disabled type="text" class="form-control" placeholder='valTransaccion' v-model="valTransaccion"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                      </div> 
                      <p :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" style="text-align:left;">
                        Factura / Referencia.
                      </p>
                      <div class="row">
                        <div class="col-md-11">
                          <div class="form-group">
                            <div class="input-group">
                              <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                <fa :icon="['fas', 'receipt']"> </fa>
                              </span>
                              <input id="inputtext" disabled type="text" class="form-control" placeholder="Opcional" v-model="refTransaccion"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                      </div> 
                      <p :id="$store.state.dark_mode == 'light' ? '' : 'color-else-text'" style="text-align:left;">
                        Pin de pago (Enviado al Cliente)
                      </p>
                      <div class="row">
                        <div class="col-md-11">
                          <div class="form-group">
                            <div class="input-group">
                              <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                <fa :icon="['fas', 'credit-card']"> </fa>
                              </span>
                              <input id="inputtext" type="text" class="form-control" placeholder="Número de Pin" v-model="numPin"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                      </div>  

                      <!-- INICIO MSJBOX -->
                      <div class="row" v-if="msjBox.visible === true">
                        
                        <div v-if="msjBox.status === true" class="col-md-11 alert alert-light-success alert-dismissible border-0 mb-4 " role="alert">
                          <strong>{{msjBox.message}} </strong> 
                          <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                        </div>
                        <div v-else class="col-md-11 alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                          <strong>{{msjBox.message}} </strong>
                          <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                        </div>
                      </div>

                      <!-- ESPACIOS -->
                      <br>
                      <!-- BOTONERA -->
                      <div class="row">
                        <div class="col-md-3">
                          <button type="button" class="btn btn-primary rounded-4 w-100 mb-2" @click="reenviarPin">    Reenviar Pin </button>
                        </div><div class="col-md-3">
                          <button type="button" class="btn btn-primary rounded-4 w-100 mb-2" @click="reenviarPinVoz"> Reenviar Pin de Voz </button>
                        </div><div class="col-md-3">
                          <button type="button" class="btn btn-danger  rounded-4 w-100 mb-2" @click="anularPago">     Anular Pago </button>
                        </div>
                        <div class="col-md-3">
                          <button type="button" class="btn btn-success rounded-4 w-100 mb-2" @click="realizarPago">   Validar Pago </button>
                        </div>
                      </div>
                          
                    </div>
                  </div>
              </div>
            </div>
          </div>
            
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import '@/assets/sass/pages/pagos.scss';  
  import { ref } from 'vue'; 

  var msjBox= ref({
    visible: false,
    status: false,
    message: ''
  });

  var msjBox2= ref({
    visible: true,
    status: true,
    message: store.getters.pagoPin.message,
  });

  var numPin    = ref('');
  const valTransaccion = store.getters.pagoPin.valTransaccion;
  const refTransaccion = store.getters.pagoPin.refTransaccion;
  //Metodos

  //RESETMSJBOX
  function resetmsjBox() {
    msjBox.value.visible = false;
    msjBox.value.status  = false;
    msjBox.value.message = '';
  };

  //MSJBOX
  function sendmsjBox(status, message) {
    msjBox.value.visible = true;
    msjBox.value.status  = status;
    msjBox.value.message = message;
  };

  //RESETMSJBOX2
  function resetmsjBox2() {
    msjBox.value.visible = false;
    msjBox.value.status  = false;
    msjBox.value.message = '';
  };

  //MSJBOX2
  function sendmsjBox2(status, message) {
    msjBox.value.visible = true;
    msjBox.value.status  = status;
    msjBox.value.message = message;
  };

  function anularPago () {

    resetmsjBox()
    resetmsjBox2()

    var data = {
      codConvenio:    store.getters.user.codEmpresa,
      codVendedor:    store.getters.user.codUsuario,
      numTransaccion: store.getters.pagoPin.id_transaccion
    }

    var anularTrVueResponse = axiosWS3.anularTrVue(data);
    anularTrVueResponse.then(res => {
      // console.log(res.data)
      if (res.data.status === true) {

        let msg = res.data.message + 'Esta transacción se cerrará en 10 segundos'
        sendmsjBox(true, msg)

        setTimeout(()  => {
          
          const PagoPin = {
            codEmpresa : store.getters.pagoPin.codEmpresa,
            codSucursal : store.getters.pagoPin.codSucursal,
            codVendedor : store.getters.pagoPin.codVendedor,
            valTransaccion :  '',
            refTransaccion :  '',
            numIdentificacion : '',
            pagoProcesado :   false,
            numTransaccio :   '',
            id_transaccion :  '',
            message :         '',
            nomSucursal:      '',
            sucursales:       '',
            urlImagen:        '',
            nomEmpresa:       ''
          }

          store.dispatch('addPagoPin', PagoPin)
        }, 10000)
      }
      else {
        sendmsjBox(false, res.data.message)
      }
    })

  };

  function reenviarPin () {

    resetmsjBox()
    resetmsjBox2()
    
    var data = {
      numTransaccion: store.getters.pagoPin.id_transaccion
    }

    var resendPinVueResponse = axiosWS3.resendPinVue(data);
    resendPinVueResponse.then(res => {
      // console.log(res.data)
      if (res.data.status === true) {

        let msg = res.data.message
        sendmsjBox(true, msg)
      }
      else {
        sendmsjBox(false, res.data.message)
      }
    })

  };

  function reenviarPinVoz () {
    resetmsjBox()
    resetmsjBox2()
    
    var data = {
      numTransaccion: store.getters.pagoPin.id_transaccion
    }

    var resendPinVozVueResponse = axiosWS3.resendPinVozVue(data);
    resendPinVozVueResponse.then(res => {
      // console.log(res.data)
      if (res.data.status === true) {

        let msg = res.data.message
        sendmsjBox(true, msg)
      }
      else {
        sendmsjBox(false, res.data.message)
      }
    })
  };

  function realizarPago () {

    resetmsjBox()
    resetmsjBox2()
    
    var data = {
      codEmpresa :        store.getters.user.codEmpresa,
      codVendedor :       store.getters.user.codUsuario,
      pinTransaccion :    numPin.value,
      numIdentificacion : store.state.pagoPin.numIdentificacion
    }

    var doPayPinAppVueResponse = axiosWS3.doPayPinAppVue(data);
    doPayPinAppVueResponse.then(res => {
      // console.log(res.data)
      if (res.data.status === true) {

        const PagoPin = {
          codEmpresa :         store.getters.pagoPin.codEmpresa,
          codSucursal :        store.getters.pagoPin.codSucursal,
          codVendedor :        store.getters.pagoPin.codUsuario,
          valTransaccion :     store.getters.pagoPin.valTransaccion,
          refTransaccion :     store.getters.pagoPin.refTransaccion,
          numIdentificacion :  store.getters.pagoPin.numIdentificacion,
          pagoProcesado : 3,
          numTransaccion :     store.getters.pagoPin.numTransaccion,
          id_transaccion :     store.getters.pagoPin.id_transaccion,
          message :            store.getters.pagoPin.message,
          nomSucursal:         store.getters.pagoPin.nomSucursal,
          sucursales:          store.getters.pagoPin.sucursales,
          urlImagen:           store.getters.pagoPin.urlImagen,
          nomEmpresa:          store.getters.pagoPin.nomEmpresa
        }

        store.dispatch('addPagoPin', PagoPin)
          
      }
      else {
        sendmsjBox(false, res.data.message)
      }
    })
    
  };

</script>