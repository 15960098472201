<template>
  <div>
    
  </div>
</template>

<script setup>

  import { onMounted } from 'vue';

    onMounted(() => {
      // router.replace("/auth/login-boxed");
      getTokenJWT ()
    });

    function getTokenJWT () {
      let user = store.getters.user;
      
      if(user.MyToken == "")
      {
        //usuario no logged
        router.replace("/passLogout");
      }
      else
      {
        // console.log("usuario logged")
      }
      //codPerfil 8, comercio no usar
      //codPerfil 11 Comercio / Cajero
      //por ahora revisar unicamente si el usuario esta logged
    }
</script>
