<template>

  <!-- Datos del pagues -->
  <teleport to="#breadcrumb">
      
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/Inicio"> Ir a Home </a></li>
                <!-- <li class="breadcrumb-item active" aria-current="page"><span>Consultar Saldo</span></li> -->
              </ol>
            </nav>
          </div>
        </li>
      </ul>

      <cambiar></cambiar>
      
  </teleport>

  <div class="layout-px-spacing">
    <div class="pagos container">
      
      <div class="pagos container">
        
        <div class="out-header-wrapper"></div>
        
        <div class="row">
          <div class="col-md-1"></div>
          
          <div class="col-md-10">
            
            <div class="hd-tab-section" align="center">
              
              <div class="accordion">
                <div class="card">
                  <div :class="$store.state.dark_mode == 'light' ? 'card-header' : 'card-header-black'" role="tab">
                    <div class="mb-0">
                      <div
                        role="menu"
                        data-bs-target="#collapse-hd-statistics-1"
                        aria-expanded="true"
                        aria-controls="collapse-hd-statistics-1"
                      >
                        <h4 class='mt-3' :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                          <fa :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'" :icon="['fas', 'search']" class="ml-2"> </fa>
                          Consultar saldo (Mensaje de texto)
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div id="collapse-hd-statistics-1">
                    <div class="card-body">
                      
                      <p :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                        Número de Identificación del cliente (Se le enviará un mensaje con el saldo disponible).
                      </p>

                      <br>
                      
                      <div class="row">
                        <div class="col-md-11">
                          <div class="form-group">
                            <div class="input-group">
                              <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? '' : 'span-input-group-text'">
                                <fa :icon="['fas', 'credit-card']"> </fa>
                              </span>
                              <input id="inputtext" type="text" class="form-control" placeholder="Número de cédula" v-model="numDocumento"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                      </div>  

                      <!-- INICIO MSJBOX -->
                      <div class="row" v-if="msjBox.visible === true">
                        
                        <div v-if="msjBox.status === true" class="col-md-11 alert alert-light-success alert-dismissible border-0 mb-4 " role="alert">
                          <strong>{{msjBox.message}} </strong> 
                          <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                        </div>
                        <div v-else class="col-md-11 alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                          <strong>{{msjBox.message}} </strong>
                          <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-3">
                          <button type="button" class="btn btn-success rounded-4 w-100" @click="consultaSaldo"> Consultar Saldo </button>
                        </div>
                      </div>
                          
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
    </div>
  </div>

  <codPerfilJWT></codPerfilJWT>
  <logOut></logOut>
</template>


<script setup>
  import '@/assets/sass/pages/pagos.scss'; 
  import { useMeta } from '@/composables/use-meta';
  import { ref } from 'vue'; 

  var msjBox= ref({
    visible: false,
    status: false,
    message: ''
  });

  var numDocumento= ref('');



  //RESETMSJBOX
  function resetmsjBox() {
    msjBox.value.visible = false;
    msjBox.value.status  = false;
    msjBox.value.message = '';
  };

  //MSJBOX
  function sendmsjBox(status, message) {
    msjBox.value.visible = true;
    msjBox.value.status  = status;
    msjBox.value.message = message;
  };

  function consultaSaldo () {
    resetmsjBox()
    // console.log('this numdocumento value, ',this.numDocumento)
    if( numDocumento.value == null ||  numDocumento.value == '')
    {
      sendmsjBox(false, 'Para continuar, digite el número de cedula del cliente a consultar.')
    }
    else
    {
      //hacer axios
      var consultaSaldoResponse = axiosWS3.consultaSaldoVue(numDocumento.value);
      consultaSaldoResponse.then(res => {
        if (res.data.status === true) 
        {
          sendmsjBox(true, res.data.message)
        }
        else
        {
          sendmsjBox(false, res.data.message)
        }
      });
    }
  }
</script>


<script>

  import logOut from '@/views/logOut/logOut.vue';
  import cambiar from '@/views/kupiComercios/pagos/components/cambiarEmpresa.vue';
  import codPerfilJWT from '@/views/kupiComercios/codPerfil/codPerfilJWT.vue';

  export default {
    components: {
      logOut,
      cambiar,
      codPerfilJWT
    },
  }
</script>
