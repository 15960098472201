<template>
           
    <!-- HOME -->
    <li class="menu">
      <router-link to="/Inicio" class="dropdown-toggle" @click="toggleMobileMenu">
          <div class="">
            <font-icons data-feather="home"> </font-icons>
            <span>{{ $t('Inicio') }}</span>
          </div>
      </router-link>
    </li>

    <!-- //PAGO QR DE KUPI COMERCIOS -->
    <li class="menu">
      <router-link to="/pagos/pagoQR" class="dropdown-toggle" @click="toggleMobileMenu">
          <div class="">
            <fa :icon="['fas', 'qrcode']" class="ml-2"> </fa> 
            <span>{{ $t('Pago con QR') }}</span>
          </div>
      </router-link>
    </li>

    <!-- //Pago con Pin DE KUPI COMERCIOS -->
    <li class="menu">
      <router-link to="/pagos/pagoPin" class="dropdown-toggle" @click="toggleMobileMenu">
          <div class="">
            <font-icons data-feather="mail"> </font-icons>
            <span>{{ $t('Pago con Pin') }}</span>
          </div>
      </router-link>
    </li>

    <!-- //ConsultarSaldo DE KUPI COMERCIOS -->
    <li class="menu">
      <router-link to="/pagos/ConsultarSaldo" class="dropdown-toggle" @click="toggleMobileMenu">
          <div class="">
            <font-icons data-feather="search"> </font-icons>
            <span>{{ $t('Consultar Saldo') }} </span>
          </div>
      </router-link>
    </li>

    <!-- //Mis Ventas COMERCIOS -->
    <li class="menu">
      <router-link to="/pagos/misVentas" class="dropdown-toggle" @click="toggleMobileMenu">
          <div class="">
            <font-icons data-feather="tag"></font-icons>
            <span>{{ $t('Mis Ventas') }}</span>
          </div>
      </router-link>
    </li>

    <br>

    <span id="taglist"> <b>{{ $t('OTROS') }} </b></span>

    <!-- //Mi cuenta DE KUPI COMERCIOS -->
    <li class="menu">
      <router-link to="/miCuenta" class="dropdown-toggle" @click="toggleMobileMenu">
          <div class="">
            <font-icons data-feather="user"> </font-icons>
            <span>{{ $t('Mi Cuenta') }}</span>
          </div>
      </router-link>
    </li>

    <!-- //Soporte COMERCIOS -->
    <li class="menu">
      <a href="https://api.whatsapp.com/send?phone=573167448990&text=&source=&data=" class="dropdown-toggle" target="_blank">
        <div class="">
          <font-icons data-feather="phone"></font-icons>
          <span>{{ $t('Soporte') }}</span>
        </div>
      </a>     
    </li>

    <!-- //Cerrar sesión DE KUPI COMERCIOS -->
    <li class="menu">
      <a class="dropdown-toggle"  data-bs-toggle="modal" data-bs-target="#modalLogout">
            <div class="text-center">
              <font-icons data-feather="log-out"> </font-icons>
              <span>{{ $t('Cerrar sesión') }}</span>
            </div>
      </a>
    </li>
    
    <br>

</template>


<script setup>
  // IMPORTE DE ICONOS
  import { onMounted } from 'vue';
  import '@/assets/sass/font-icons/fontawesome/css/regular.css';
  import '@/assets/sass/font-icons/fontawesome/css/fontawesome.css';
  import feather from 'feather-icons';


  onMounted(() => {
    feather.replace();
  });

</script>

<style>
  #taglist {
    margin: 10px;
    text-justify: auto;
  }
</style>