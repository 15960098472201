<template>
  
  <!-- Datos del pagues -->
  <teleport to="#breadcrumb">
    <ul class="navbar-nav flex-row">
      <li>
        <div class="page-header">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/Inicio"> Pagos </a></li>
              <li class="breadcrumb-item active" aria-current="page"><span>Mis Ventas</span></li>
            </ol>
          </nav>
        </div>
      </li>
    </ul>
    <cambiar></cambiar>
  </teleport>
  <br>

  <datamisVentas></datamisVentas>
  <codPerfilJWT></codPerfilJWT>
  <logOut></logOut>
</template>

<script>

  import logOut from '@/views/logOut/logOut.vue';
  import cambiar from '@/views/kupiComercios/pagos/components/cambiarEmpresa.vue';
  import datamisVentas from './components/misVentas/datamisVentas.vue';
  import codPerfilJWT from '@/views/kupiComercios/codPerfil/codPerfilJWT.vue';


  export default {
    components: {
      logOut,
      cambiar,
      datamisVentas,
      codPerfilJWT
    },
  }
</script>