const reloadPage = false


const countryList = [
  { code: 'zh', name: 'Chinese' },
  { code: 'da', name: 'Danish' },
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'el', name: 'Greek' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'ru', name: 'Russian' },
  { code: 'es', name: 'Spanish' },
  { code: 'sv', name: 'Swedish' },
  { code: 'tr', name: 'Turkish' },
]

const user = {
  codUsuario: "",
  nomUsuario: "",
  apeUsuario: "",
  numDocumento: "",
  tokenLogin: "",
  MyToken: "",
  //DATOS PARA KUPI COMERCIOS
  codEmpresa: "",
  nomEmpresa: "",
  codSucursal: "",
  sucursales: "",
  cantComercios: "",
  urlImagen: "",
  codPerfil: ""
}

const comercios = []

const sucursales = []

const pagoPin = {
  codEmpresa : '',
  codSucursal : '',
  codVendedor : '',
  valTransaccion : '',
  refTransaccion : '',
  numIdentificacion: '',
  pagoProcesado: false,
  numTransaccion: '',
  id_transaccion: '',
  message: '',
  nomSucursal: '',
  sucursales: '',
  urlImagen: '',
  nomEmpresa: ''
}


const pagoQR = {
  codEmpresa : '',
  codSucursal : '',
  codVendedor : '',
  valTransaccion : '',
  refTransaccion : '',
  codQR: '',
  numTransaccion: '',
  id_transaccion: '',
  message: '',
  nomSucursal: '',
  sucursales: '',
  urlImagen: '',
  nomEmpresa: ''
}


// /////////////////////////////////////////////
// State
// información compartida que maneja una aplicación y a la cual es recomendable 
// no actualizarla (GET y SET) sino mutarla.
// /////////////////////////////////////////////

const state = {
  layout: 'app',
  is_show_sidebar: true,
  is_show_search: false,
  is_dark_mode: true,
  is_logged: false,
  dark_mode: 'dark',
  locale: 'es',
  menu_style: 'vertical',
  layout_style: 'full',
  reloadPage,
  countryList,
  user,
  comercios,
  sucursales,
  pagoPin,
  pagoQR
}

export default state