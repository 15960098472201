<template>

  <div class="layout-px-spacing">
    <div class="pagos container">
      <div class="pagos container">
        <!-- HEADER DE LA PAGINA -->
        <div class="out-header-wrapper"></div>
       
        <div class="row">

          <div class="col-md-1"></div>

          <div class="col-md-10">
            <div class="hd-tab-section" align="center">
              <div class="accordion">
                <div class="card">

                  <div :class="$store.state.dark_mode == 'light' ? 'card-header' : 'card-header-black'" role="tab" >
                    <div class="mb-0">
                      <div
                        role="menu"
                        data-bs-target="#collapse-hd-statistics-1"
                        aria-expanded="true"
                        aria-controls="collapse-hd-statistics-1"
                      >
                        <h4 class="h4font" :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                          <fa :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'" :icon="['fas', 'qrcode']" class="ml-2"> </fa>
                          Generar Pago con QR
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div id="collapse-hd-statistics-1">
                      <div class="card-body"> 
                        
                        <p :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                          Valor.
                        </p>
                        <div class="row">
                          <div class="col-md-11">
                            <div class="form-group">
                              <div class="input-group">
                                 <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                  <fa :icon="['fas', 'dollar-sign']"> </fa>
                                </span>
                                <input id="inputtext" type="text" class="form-control" placeholder="Valor" v-model="valTransaccion"/>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1"></div>
                        </div> 
                        <p :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                          Factura / Referencia.
                        </p>
                        <div class="row">
                          <div class="col-md-11">
                            <div class="form-group">
                              <div class="input-group">
                                 <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                  <fa :icon="['fas', 'receipt']"> </fa>
                                </span>
                                <input id="inputtext" type="text" class="form-control" placeholder="Opcional" v-model="refTransaccion"/>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1"></div>
                        </div> 

                        <!-- INICIO MSJBOX -->
                        <div class="row" v-if="msjBox.visible === true">
                          
                          <div v-if="msjBox.status === true" class="col-md-11 alert alert-light-success alert-dismissible border-0 mb-4 " role="alert">
                            <strong>{{msjBox.message}} </strong> 
                            <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                          </div>
                          <div v-else class="col-md-11 alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                            <strong>{{msjBox.message}} </strong>
                            <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                          </div>
                        </div>

                        <br>
                        
                        <div class="row">
                          <div class="col-md-8"></div>
                          <div class="col-md-3">
                            <button type="button" class="btn btn-success rounded-4 w-100" @click="generarPagoQR"> Registrar </button>
                          </div>
                        </div>
                            
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-1"></div>

        </div>
      </div>
    </div>
  </div>


  
</template>

<script setup>
  import '@/assets/sass/pages/pagos.scss';  
  import { ref } from 'vue'; 

  var msjBox= ref({
    visible: false,
    status: false,
    message: ''
  });

  
  var valTransaccion    = ref('');
  var refTransaccion    = ref('');

  //RESETMSJBOX
  function resetmsjBox() {
    msjBox.value.visible = false;
    msjBox.value.status  = false;
    msjBox.value.message = '';
  };

  //MSJBOX
  function sendmsjBox(status, message) {
    msjBox.value.visible = true;
    msjBox.value.status  = status;
    msjBox.value.message = message;
  };

  function generarPagoQR () {
    
    resetmsjBox()
    
    if(valTransaccion.value == null || valTransaccion.value == '')
    {
      sendmsjBox(false, 'Ingrese un valor para la transacción.')
    }
    else
    {

      var data = {
        codEmpresa :      store.getters.user.codEmpresa,
        codSucursal :     store.getters.user.codSucursal,
        codVendedor :     store.getters.user.codUsuario,
        valTransaccion :  valTransaccion.value,
        refTransaccion :  refTransaccion.value
      }

      // console.log(data);
      //hacer axios
      var regPayQrAppVueResponse = axiosWS3.regPayQrAppVueVue(data);
      regPayQrAppVueResponse.then(res => {
        // console.log(res)
        if (res.data.status === true) 
        {
          sendmsjBox(res.data.status, res.data.message) 

          const PagoQR = {
            codEmpresa :      store.getters.user.codEmpresa,
            codSucursal :     store.getters.user.codSucursal,
            codVendedor :     store.getters.user.codUsuario,
            valTransaccion :  data.valTransaccion,
            refTransaccion :  data.refTransaccion,
            pagoProcesado : 2,
            codQR:            res.data.codQr,
            numTransaccion :  res.data.id_transaccion,
            id_transaccion :  res.data.id_transaccion,
            message :         res.data.message,
            nomSucursal:      store.getters.user.nomSucursal,
            sucursales:       store.getters.user.sucursales,
            urlImagen:        store.getters.user.urlImagen,
            nomEmpresa:       store.getters.user.nomEmpresa
          }

          store.dispatch('addPagoQR', PagoQR)

        }
        else
        {
          sendmsjBox(false, res.data.message)
        }
      });
    }
  };
</script>