<template>
  <div class="layout-px-spacing">
    <div class="pagos container">
      <div class="pagos container">
        
        <!-- HEADER DE LA PAGINA -->
        <div class="out-header-wrapper"></div>
        <div class="hd-tab-section" align="center">
          <div class="row">

            <div class="col-md-1"></div>

            <div class="col-md-10">
              <div class="accordion">
                <div class="card">

                  <div :class="$store.state.dark_mode == 'light' ? 'card-header' : 'card-header-black'" role="tab" >
                    <div class="mb-0">
                      <div
                        role="menu"
                        data-bs-target="#collapse-hd-statistics-1"
                        aria-expanded="true"
                        aria-controls="collapse-hd-statistics-1"
                      >
                        <h4 class="h4font" :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'" >
                          <fa :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'" :icon="['fas', 'qrcode']" class="ml-2"> </fa>
                          Generar Pago con QR
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div id="collapse-hd-statistics-1">
                      <div class="card-body"> 
                        
                        
                        <div class="row">
                          <div class="col-md-6">

                            <p :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                              Valor.
                            </p>

                            <div class="form-group">
                              <div class="input-group">
                                <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                  <fa :icon="['fas', 'dollar-sign']"> </fa>
                                </span>
                                <input id="inputtext" disabled type="text" class="form-control" placeholder="Valor" v-model="valTransaccion"/>
                              </div>
                            </div>
                            
                            <p :id="$store.state.dark_mode == 'light' ? 'color-text' : 'color-else-text'">
                              Factura / Referencia.
                            </p>

                            <div class="form-group">
                              <div class="input-group">
                                <span class="input-group-text mt-2 mb-2" :id="$store.state.dark_mode == 'light' ? 'span-input-group-text-white' : 'span-input-group-text'">
                                  <fa :icon="['fas', 'receipt']"> </fa>
                                </span>
                                <input id="inputtext" disabled type="text" class="form-control" placeholder="Opcional" v-model="refTransaccion"/>
                              </div>
                            </div>

                          </div>

                          <div class="col-md-6">
                            <div class="col w-100" id="PagoQR">
                              <div class="qrcode-style" id="qrcode"></div>
                            </div>
                          </div>
                        </div> 

                        <!-- INICIO MSJBOX -->
                        <div class="row" v-if="msjBox.visible === true">
                          
                          <div v-if="msjBox.status === true" class="col-md-11 alert alert-light-success alert-dismissible border-0 mb-4 " role="alert">
                            <strong>{{msjBox.message}} </strong> 
                            <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                          </div>
                          <div v-else class="col-md-11 alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                            <strong>{{msjBox.message}} </strong>
                            <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                          </div>
                        </div>

                        <br>
                        
                        <div class="row">
                          <div class="col-md-3">
                            <button type="button" class="btn btn-danger rounded-4 w-100" @click="anularPagoQR"> Anular </button>
                          </div>
                          <div class="col-md-3">
                            <button type="button" class="btn btn-success rounded-4 w-100" @click="generarPagoQR"> Registrar </button>
                          </div>
                          <div class="col-md-3"></div>
                          <div class="col-md-3"></div>
                        </div>
                            
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-1"></div>

          </div>
        </div>
      </div>
    </div>
  </div>


  
</template>

<script setup>
  import '@/assets/sass/pages/pagos.scss';  
  import QRCode from 'qrcodejs2'
  import { onMounted, ref } from 'vue';

  var msjBox= ref({
    visible: false,
    status: false,
    message: ''
  });

  var value= store.getters.pagoQR.codQR;
  let valTransaccion    = ref(store.getters.pagoQR.valTransaccion);
  let refTransaccion    = ref(store.getters.pagoQR.refTransaccion);

  onMounted(() => {
    qrcode();
  });

  const qrcode = () => {
    
    const address = store.getters.pagoQR.codQR
    // console.log(address)
    const qrcode = new QRCode('qrcode', {
      width: 175, // establece el ancho en píxeles
      height: 175, // establece la altura en píxeles
      text: value // Establezca el contenido del código QR o la dirección de redireccionamiento,
    })
    // qrcode.makeCode(route.params.address)
  };

  //RESETMSJBOX
  function resetmsjBox() {
    msjBox.value.visible = false;
    msjBox.value.status  = false;
    msjBox.value.message = '';
  };

  //MSJBOX
  function sendmsjBox(status, message) {
    msjBox.value.visible = true;
    msjBox.value.status  = status;
    msjBox.value.message = message;
  };

  function anularPagoQR () {

    resetmsjBox()

    var data = {
      codConvenio: store.getters.user.codEmpresa,
      codVendedor: store.getters.user.codUsuario,
      numTransaccion: store.getters.pagoQR.id_transaccion
    }

    var anularTrVueResponse = axiosWS3.anularTrVue(data);
    anularTrVueResponse.then(res => {
      // console.log(res.data)
      if (res.data.status === true) {

        let msg = res.data.message + 'Esta transacción se cerrará en 10 segundos'

        sendmsjBox(true, msg)


        setTimeout(()  => {

          const PagoQR = {
            codEmpresa :      store.getters.user.codEmpresa,
            codSucursal :     store.getters.user.codSucursal,
            codVendedor :     store.getters.user.codUsuario,
            valTransaccion :  '',
            refTransaccion :  '',
            pagoProcesado :   false,
            codQR:            '',
            numTransaccion :  '',
            id_transaccion :  '',
            message :         '',
            nomSucursal:      '',
            sucursales:       '',
            urlImagen:        '',
            nomEmpresa:       ''
          }

          store.dispatch('addPagoQR', PagoQR)

        }, 10000)
      }
      else {
        sendmsjBox(false, res.data.message)
      }
    })

  };
        
  function generarPagoQR () {
    
    resetmsjBox()
    
    var data = {
      numTransaccion:   store.getters.pagoQR.id_transaccion,
      codVendedor :     store.getters.user.codUsuario
    }
    //hacer axios
    var getInfoQrVueResponse = axiosWS3.getInfoQrVue(data);
    getInfoQrVueResponse.then(res => {

      if (res.data.status === true) 
      {
        // console.log(res.data)
        //data.cod estado diferente a cerrada
        if (res.data.codEstado !== '3') {
          sendmsjBox(false, 'La transacción aun se encuentra abierta.')
        }
        else {

          const PagoQR = {
            codEmpresa :      store.getters.pagoQR.codEmpresa,
            codSucursal :     store.getters.pagoQR.codSucursal,
            codVendedor :     store.getters.pagoQR.codUsuario,
            valTransaccion :  valTransaccion.value,
            refTransaccion :  refTransaccion.value,
            pagoProcesado :   3,
            codQR:            res.data.codQr,
            numTransaccion :  res.data.numTransaccion,
            id_transaccion :  res.data.numTransaccion,
            message :         '',
            nomSucursal:      store.getters.pagoQR.nomSucursal,
            sucursales:       store.getters.pagoQR.sucursales,
            urlImagen:        store.getters.pagoQR.urlImagen,
            nomEmpresa:       store.getters.pagoQR.nomEmpresa
          }
          
          store.dispatch('addPagoQR', PagoQR)
        }
      }
      else
      {
        setTimeout(()  => {
          sendmsjBox(false, res.data.message)
        }, 5000)
      }
    });
  };

</script>


<style>
  #PagoQR{
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    /* -ms-transform: translate(-100%, -25%);
    transform: translate(0%, 0%); */
  }
</style>