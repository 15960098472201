const actions = {

  // /////////////////////////////////////////////
  // Las acciones son aquellas que realizan las mutaciones
  // /////////////////////////////////////////////
  login_access({ commit }, value){
    // console.log('Valor que le llega al login_acces', value);
    commit('is_logged', value);
  },

  reload_page({ commit }, value){
    // console.log('Valor que le llega al login_acces', value);
    commit('reload_page', value);
  },

  login_user( {commit} , data) {
    commit('user_logIn', data);
  },

  logOut_user( {commit} ) {
    commit('user_logOut');
  },

  ////////////////////////////////////////////////////////
  ////// ACiones sobre comercios
  ////////////////////////////////////////////////////////
  addComercios ({ commit }, comercios) {
    commit('UserComercios', comercios)
  },

  addComercioActual ({ commit }, data) {
    // console.log(`Envio su nomEmpressa: ${data.nomEmpresa}, su codEmpresa ${data.codEmpresa}`)
    commit('UserComercioActual', data)
  },

  addSucursales ({ commit }, sucursales) {
    commit('UserSucursalesComercioActual', sucursales)
  },


  deleteSucursales ({ commit }) {
    const sucursales = []
    commit('UserDeleteSucursales', sucursales)
  },


  deleteComercios ({ commit }) {
    const comercios = []
    commit('UserDeleteComercios', comercios)
  },

  
  addSucursalActual ({ commit }, data) {
    // console.log(`Envio su nomSucursal: ${data.codSucursal}, su codSucursal ${data.nomSucursal}`)
    commit('UserSetSucursal', data)
  },

  // /////////////////////////////////////////////
  // Acciones PAGOS
  // /////////////////////////////////////////////
  addPagoPin ({ commit }, PagoPin) {
    commit('KcAddPagoPin', PagoPin)
  },

  
  addPagoQR ({ commit }, PagoQR) {
    commit('KcAddPagoQR', PagoQR)
  },
  

  deletePagoPin ({ commit }) {
    const pagoPin = {
      codEmpresa : '',
      codSucursal : '',
      codVendedor : '',
      valTransaccion : '',
      refTransaccion : '',
      numIdentificacion: '',
      pagoProcesado: false,
      numTransaccion: '',
      id_transaccion: '',
      message: '',
      nomSucursal: '',
      sucursales: '',
      urlImagen: '',
      nomEmpresa: ''
    }
    commit('KcDelPagoPin', pagoPin)
  },


  deletePagoQR({ commit }) {
    const pagoQR = {
      codEmpresa : '',
      codSucursal : '',
      codVendedor : '',
      valTransaccion : '',
      refTransaccion : '',
      codQR: '',
      numTransaccion: '',
      id_transaccion: '',
      message: '',
      nomSucursal: '',
      sucursales: '',
      urlImagen: '',
      nomEmpresa: ''
    }
    commit('KcDelPagoQR', pagoQR)
  },
  // /////////////////////////////////////////////
  // FINAL Acciones PAGOS
  // /////////////////////////////////////////////



}

export default actions
